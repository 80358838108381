import React, { Component } from "react";

import QuestionCard from "./QuestionCard";

import { question3, question3Hindi } from "../../utils";
import zapierService from "../../service/zapier";
import arrow from "../../resources/arrow.svg";
//change name of class component
class Question2 extends Component {
	constructor(props) {
		super(props);
		this.pageRef = React.createRef();
		let lang = localStorage.getItem("lng");

		const languageHindi = lang == "hn" ? true : false;
		this.state = {
			questions: languageHindi ? question3Hindi : question3,
			showForwardButton: false,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	onSelect = (
		questionNumber,
		selectedAnswerIndex,
		selectedText,

		isPreviouslySelected,
		isMultipleAnswers,
		answerWeightage
	) => {
		//spreading the current state
		let dummyStateQuestions = [...this.state.questions];
		let totalQuestions = this.state.questions.length;

		//copying already/previous selected value of the questionNumber 1.1 or 1.2 ... for easy to read code, will be put back later.
		let alreadySelectedValue =
			dummyStateQuestions[questionNumber].selectedValue;

		//questionWeightage
		let alreadyQuestionScore =
			dummyStateQuestions[questionNumber].questionScore;
		// think for the case of multiple answers
		if (isMultipleAnswers) {
			//toggle for multiple clicks on an option
			dummyStateQuestions[questionNumber].options[
				selectedAnswerIndex
			].isSelected = isPreviouslySelected ? 0 : 1;

			alreadyQuestionScore += answerWeightage;
			//appending more  into the alreadySelectedValue
			alreadySelectedValue += `${selectedText} & `;
		} else {
			//toggle for multiple clicks on an option
			dummyStateQuestions[questionNumber].options[
				selectedAnswerIndex
			].isSelected = isPreviouslySelected ? 0 : 1;

			//finding the index of the previous selected option/text;

			let previousSelectedOption = dummyStateQuestions[
				questionNumber
			].options.findIndex((option) => option.text == alreadySelectedValue);

			//turning off the previous selected answer
			if (previousSelectedOption >= 0) {
				dummyStateQuestions[questionNumber].options[
					previousSelectedOption
				].isSelected = 0;
			}

			//only single selection of value
			alreadySelectedValue = selectedText;

			//question score
			alreadyQuestionScore = answerWeightage;
		}

		//think of an if condition here,else code will break

		if (dummyStateQuestions[questionNumber + 1]) {
			dummyStateQuestions[questionNumber + 1].toShow = true;
			setTimeout(() => {
				this.props && this.props.scrollFunc();
			}, 100);
		}

		//putting the selectedValue back to the original array;
		dummyStateQuestions[questionNumber].selectedValue = alreadySelectedValue;
		dummyStateQuestions[questionNumber].questionScore = alreadyQuestionScore;

		this.setState({ question: { ...dummyStateQuestions } }, () => {
			console.log(this.state.questions, "selectedQuestions");


			const payloadQuestions = this.state.questions.map((question, index) => {
				return question.selectedValue;
			});

			console.log(payloadQuestions,"payload questions")

			if(payloadQuestions.includes("Yes, I want to become like them one day")){
				window.fbq('trackCustom',  'HealthScore_Q3_Edtech_Want_To_Become');
			}
			if(payloadQuestions.includes("Yes, but not worried")){
				window.fbq('trackCustom',  'HealthScore_Q3_Edtech_Not_Worried');
			}
			if(payloadQuestions.includes("No, I don’t know anything about them")){
				window.fbq('trackCustom',  'HealthScore_Q3_Edtech_Dont_Know');
			}


			if(payloadQuestions.includes("Ask students to refer more students. &")){
				window.fbq('trackCustom',  'HealthScore_Q3_Student_Count_Refer');
			}
			if(payloadQuestions.includes("Pamphlets, newspaper ads, etc. &")){
				window.fbq('trackCustom',  'HealthScore_Q3_Student_Count_Pamphlet');
			}
			if(payloadQuestions.includes("YouTube, Telegram, Facebook, Instagram pages &")){
				window.fbq('trackCustom',  'HealthScore_Q3_Student_Count_Yt');
			}
			if(payloadQuestions.includes("Trial classes, scholarship tests, etc. & ")){
				window.fbq('trackCustom',  'HealthScore_Q3_Student_Count_Trial_Class');
			}


			if(payloadQuestions.includes("Yes, I want to learn how to get more students")){
				window.fbq('trackCustom',  'HealthScore_Q3_Branding_Marketing_Yes_More_Students');
			}
			if(payloadQuestions.includes("Yes, I want to make it a big brand like Byju’s")){
				window.fbq('trackCustom',  'HealthScore_Q3_Branding_Marketing_Yes_Big_Brand');
			}
			if(payloadQuestions.includes("No, I am doing well")){
				window.fbq('trackCustom',  'HealthScore_Q3_Branding_Marketing_No');
			}


			if (this.state.questions[totalQuestions - 1].selectedValue) {
				// this.handleForwardButton();
				this.sendZapierData();
			}
		});
	};

	handleForwardButton() {
		this.setState({
			showForwardButton: true,
		});
	}

	sendZapierData = () => {
		const { questions } = this.state;
		const { userDetails } = this.props;

		console.log("Make Payload", userDetails);
		const payloadQuestions = questions.map((question, index) => {
			return `Answer 3.${index + 1} -> ${question.selectedValue}`;
		});

		let totalScore = questions.reduce((acc, curr) => {
			return acc + curr.questionScore;
		}, 0);

		console.log(payloadQuestions, "payloadQuestions");

		const payloadFor3 = {
			question3: payloadQuestions.join("\n  "),
			question3Score: totalScore,
			...userDetails,
		};

		localStorage.setItem("question3Score", totalScore);

		zapierService(payloadFor3)
			.then((res) => {
				console.log(res, "+++_++++++ 2222");
				// history.push("/questions", body);
				this.props.navigation("next");
			})
			.catch((err) => {
				console.log(err);
			});

		//this.props.navigation("next");
		//
		//do your stuff here
	};

	render() {
		const { questions, showForwardButton } = this.state;
		return (
			<div className="QuestionWrapper" ref={this.pageRef}>
				{/* <h1 style={{ color: "white" }}>QUESTION 2</h1>
				<h1 style={{ color: "white" }}>QUESTION 1</h1> */}
				{questions.map((ques, index) => {
					return (
						<QuestionCard
							question={ques}
							key={index}
							source={index}
							onSelect={this.onSelect}
						/>
					);
				})}

				{showForwardButton ? (
					<button
						onClick={() => {
							this.sendZapierData();
						}}
						className="zindex_forward"
						style={{ zIndex: "5" }}
					>
						<img src={arrow} />
					</button>
				) : (
					""
				)}
			</div>
		);
	}
}

export default Question2;
