import React, { useEffect, useState } from "react";
import clasplusLogo from"../../resources/NewHomepages/Homepage3/classplus.png"
import meter from"../../resources/NewHomepages/Homepage3/meter.png"

import { Link } from "react-router-dom";
import { welcomePageTextsEng, welcomePageTextsHi } from "../../utils";
import { useTranslation, Trans } from "react-i18next";

import "./HomePage3.css";

const Hompeage3 = () => {

    useEffect(() => {
		const vars = {};
		window.location.href.replace(
			/[?&]+([^=&]+)=([^&]*)/gi,
			function (m, key, value) {
				vars[key] = value;
			}
		);

		if (vars["utm_source"]) {
            localStorage.setItem("utm_source", vars["utm_source"]);

        }

        if (vars["utm_medium"]) {
            localStorage.setItem("utm_medium", vars["utm_medium"]);

        }

        if (vars["utm_campaign"]) {
            localStorage.setItem("utm_campaign", vars["utm_campaign"]);

        }
		// setLang(vars["lang"]);
		// localStorage.setItem("lng", language);
		// props.i18n.changeLanguage(language);
	}, []);

	let lang = localStorage.getItem("lng");

	const languageHindi = lang == "hn" ? true : false;
	let staticTexts = languageHindi ? welcomePageTextsHi : welcomePageTextsEng;
	console.log(lang, "+++++welcome");

	const handleClick = () => {
        window.fbq('trackCustom',  'HealthScore_Start_Now');
        }

    return (
        <div className="homepage3-container">
            <img src={clasplusLogo} id="homepage3-classpluslogo" />
            <img src={meter} id="homepage3-meter"/>

            <h3 id="homepage3-headingText">Your coaching health score has decreased 😨</h3>
            <p id="homepage3-text">Find out where your institute stands amongst the other 10Lac educators of India?</p>

        <div className="homepage3-CTA-div">
        <Link style={{width: "100%", display:"flex",flexDirection:"row",justifyContent:"center" }} to={`/details?lang=${lang}`} onClick={ () => handleClick()}> 
        <button id="homepage3-CTA">Start your free assesment
        <lottie-player src="https://assets9.lottiefiles.com/packages/lf20_Sz5T65.json" 
                    speed="1" id="lottie" loop  autoplay>
                </lottie-player>
        </button></Link>
            <p id="homepahe3-CTA-bottomtext">Takes less than a min</p>
        </div>

        </div>
    )
}

export default Hompeage3
