import React, { Component } from "react";

import "./questions.css";
import backBtn from "../../resources/backBtn.png";
import crossBtn from "../../resources/crossBtn.png";
import SliderCard from "./SliderCard";
import Question1List from "./Question1";
import Question2List from "./Question2";
import Question3List from "./Question3";
import Question4List from "./Question4";

import Modal from "../Modal";
import arrow from "../../resources/arrow.svg";

// import WelcomePage from './components/welcomePage';

class Questions extends Component {
	constructor(props) {
		super(props);
		this.pageRef = React.createRef();
		this.state = {
			selectedQuestionPhase: 1,
			showForwardButton: false,
			backSelectionStack: [],
			nextSelectionStack: [],
		};
	}

	componentDidMount() {
		let showQuestion4 =
			this.props.location.state && this.props.location.state.showQuestion4;

		if (showQuestion4) {
			this.setState({
				selectedQuestionPhase: 4,
			});
		}
		console.log(showQuestion4, "++++++ppppp");
		window.fbq('trackCustom',  'HealthScore_Questions_Pageloaded');

	}

	onNavigation = (source) => {
		// handle for case less than 0 and for the last index

		this.setState(
			(prevState) => {
				let dummyBackSelectionState = [...prevState.backSelectionStack];
				let dummyNextSelectionState = [...prevState.nextSelectionStack];
				if (
					source == "prev" &&
					!dummyBackSelectionState.includes(prevState.selectedQuestionPhase)
				) {
					dummyBackSelectionState = [
						...dummyBackSelectionState,
						prevState.selectedQuestionPhase,
					];
				} else {
					let selectedQuestionIndex = dummyBackSelectionState.indexOf(
						prevState.selectedQuestionPhase + 1
					);
					console.log(
						selectedQuestionIndex,
						prevState.selectedQuestionPhase,
						"+_+_++++this.state.backSelectionStack"
					);
					if (selectedQuestionIndex > -1) {
						dummyBackSelectionState.splice(selectedQuestionIndex, 1);
					}
				}

				let showForwardButton = false;
				if (source == "prev") {
					showForwardButton = true;
				} else if (source == "next") {
					if (
						!dummyNextSelectionState.includes(
							prevState.selectedQuestionPhase + 1
						)
					) {
						dummyNextSelectionState.push(prevState.selectedQuestionPhase + 1);
					}
					if (prevState.selectedQuestionPhase + 1 == 4) {
						console.log("__--____");
						showForwardButton = false;
					} else if (
						dummyNextSelectionState.some((r) =>
							dummyBackSelectionState.includes(r)
						)
					) {
						showForwardButton = true;
					}
				}

				console.log(
					dummyBackSelectionState,
					"+_+_++++this.state.backSelectionStack",
					showForwardButton,
					dummyNextSelectionState
				);

				return {
					...prevState,
					selectedQuestionPhase:
						source == "next"
							? prevState.selectedQuestionPhase + 1
							: prevState.selectedQuestionPhase - 1,
					backSelectionStack: [...dummyBackSelectionState],
					nextSelectionStack: [...dummyNextSelectionState],
					showForwardButton,
				};
			},
			() => {}
		);

		console.log(this.state.selectedQuestionPhase, "phase")

		if(this.state.selectedQuestionPhase == 2){
			window.fbq('trackCustom',  'HealthScore_Q2_Back');
		}
		 if(this.state.selectedQuestionPhase == 3){
			window.fbq('trackCustom',  'HealthScore_Q3_Back');
		}
		if(this.state.selectedQuestionPhase == 3){
			window.fbq('trackCustom',  'HealthScore_Q4_Back');
		}

	};

	scrollQuestion = () => {
		console.log("WORKING");
		this.pageRef &&
			this.pageRef.current &&
			this.pageRef.current.scrollBy(0, window.innerHeight);
	};

	setmodalStatus = (status) => {
		this.setState({
			modalStatus: status,
		});
	};

	render() {
		const { selectedQuestionPhase, modalStatus, showForwardButton } =
			this.state;
		let userDetails = { ...this.props.location.state };
		let localUserDetails = JSON.parse(localStorage.getItem("userDetails"));

		if (!Object.keys(userDetails).length) {
			userDetails = { ...localUserDetails };
		}

		console.log(userDetails, "++++this.props.location.state");
		return (
			<React.Fragment>
				<div className="questions-container" ref={this.pageRef}>
					<div className="navBar">
						<img
							src={backBtn}
							onClick={() => {
								if (selectedQuestionPhase != 1) this.onNavigation("prev");
								else {
									
									window.fbq('trackCustom',  'HealthScore_Q1_Back');
									this.props.history.push("/details");}
							}}
						/>
						<div className="questionCount">
							Question{" "}
							{selectedQuestionPhase == 1
								? 1
								: selectedQuestionPhase == 2
								? 2
								: selectedQuestionPhase == 3
								? 3
								: 4}{" "}
							of 4
						</div>
						<img
							src={crossBtn}
							onClick={() => this.setState({ modalStatus: "question-modal" })}
						/>
						{/* <button
						onClick={() => {
							this.onNavigation("prev");
						}}
					>
						PREV
					</button> */}
					</div>
					{
						selectedQuestionPhase == 1 ? (
							<Question1List
								navigation={this.onNavigation}
								scrollFunc={this.scrollQuestion}
								userDetails={userDetails}
							/>
						) : selectedQuestionPhase == 2 ? (
							<Question2List
								navigation={this.onNavigation}
								scrollFunc={this.scrollQuestion}
								userDetails={userDetails}
							/>
						) : selectedQuestionPhase == 3 ? (
							<Question3List
								navigation={this.onNavigation}
								scrollFunc={this.scrollQuestion}
								userDetails={userDetails}
							/>
						) : selectedQuestionPhase == 4 ? (
							<Question4List
								name={this.state.name}
								scrollFunc={this.scrollQuestion}
								userDetails={userDetails}
							/>
						) : (
							""
						)

						//  (
						// 	<SliderCard />
						// )
					}
				</div>
				{modalStatus ? (
					<Modal
						modalStatus={modalStatus}
						setmodalStatus={this.setmodalStatus}
					/>
				) : (
					""
				)}

				{showForwardButton ? (
					<button
						onClick={() => {
							this.onNavigation("next");
						}}
						className="zindex_forward"
					>
						<img src={arrow} />
					</button>
				) : (
					""
				)}
			</React.Fragment>
		);
	}
}

export default Questions;
