import React, { useEffect, useState } from "react";
import "./HomePage1.css";

import { welcomePageTextsEng, welcomePageTextsHi } from "../../utils";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

import classplusLogo from "../../resources/NewHomepages/Homepage1/classplus.png";
import SG from "../../resources/NewHomepages/Homepage1/SG.png";

import Bullet from "../../resources/NewHomepages/Homepage1/bullet.png";

const HomePage1 = () => {

    useEffect(() => {
		const vars = {};
		window.location.href.replace(
			/[?&]+([^=&]+)=([^&]*)/gi,
			function (m, key, value) {
				vars[key] = value;
			}
		);

        if (vars["utm_source"]) {
            localStorage.setItem("utm_source", vars["utm_source"]);

        }

        if (vars["utm_medium"]) {
            localStorage.setItem("utm_medium", vars["utm_medium"]);

        }

        if (vars["utm_campaign"]) {
            localStorage.setItem("utm_campaign", vars["utm_campaign"]);

        }

		// setLang(vars["lang"]);
		// localStorage.setItem("lng", language);
		// props.i18n.changeLanguage(language);
	}, []);

    const handleClick = () => {
        window.fbq('trackCustom',  'HealthScore_Start_Now');
        }
    
	let lang = localStorage.getItem("lng");

	const languageHindi = lang == "hn" ? true : false;
	let staticTexts = languageHindi ? welcomePageTextsHi : welcomePageTextsEng;
	console.log(lang, "+++++welcome");

    return (
        <div className="homepage1-container">
            <img src={classplusLogo} id="homepage1-classplus-logo"/>
            <div className="homepage1-top">
                
                <img src={SG} id="homepage1-SG"/>
                <div className="homepage1-text-sg">
                <div className="homepage1-top-text">
                    <p style={{fontSize:"17px",fontStyle: "italic", marginBottom:"5px"}}>“Assessment is the first step to improvement!”</p>
                    <span style={{fontSize:"16px"}}>-Sourav Ganguly</span>
                    <span style={{fontSize:"12px", opacity:"0.6"}}>MENTOR DADA</span>
                </div>
                
                </div>


            </div>   

            <div className="homepage1-bottom">
                <div className="homepage1-bottom-header">
                <h1>Know your Coaching’s Health Score</h1>
                </div>
                <div className="homepage1-bottom-content">


                <div className="homepage1-bottom-text">

               
                <p style={{fontSize:"12px", marginBottom:"5px",color: "rgba(255, 255, 255, 0.87)"}}>TAKE THIS ASSESSMENT TO KNOW</p>
                <div className="homepage1-bulletpoints">
                    <img src={Bullet}/><span className="homepage1-bullets">Your rank among 10 lakh educators</span>
                </div>
                <div className="homepage1-bulletpoints">
                    <img src={Bullet}/><span>Your institute’s growth rate</span>
                </div>
                </div>

                <div className="homepage1-CTA-div">

                <Link style={{width: "100%", display:"flex",flexDirection:"row",justifyContent:"center"  }} to={`/details?lang=${lang}`} onClick={() => handleClick()}> 
                <button id="homepage1-CTA">
                    Start your free assesment
                    <lottie-player src="https://assets9.lottiefiles.com/packages/lf20_Sz5T65.json" 
                    speed="1" id="lottie" loop  autoplay>
                </lottie-player>
                    </button>
                </Link>
                <p>Takes less than a min</p>
                </div>
                </div>
            </div>          

        </div>
    )
}

export default HomePage1
