const healthScoreFormula = (dataPoints) => {
	//do some logic here
	let sum = 0;
	let question1Score = parseInt(localStorage.getItem("question1Score"));
	let question2Score = parseInt(localStorage.getItem("question2Score"));
	let question3Score = parseInt(localStorage.getItem("question3Score"));
	let question4Score = parseInt(localStorage.getItem("question4Score"));

	sum = question1Score + question2Score + question3Score + question4Score;

	return sum;
};

export default healthScoreFormula;
