import { React, useEffect, useState, useRef } from "react";
import { Button, Divider } from "semantic-ui-react";
import "./detailsInput.css";
import backBtn from "../../resources/backBtn.png";
import { Link, useHistory } from "react-router-dom";

import Modal from "../Modal/index";
import zapierService from "../../service/zapier";
import {
	fetchUTMs,
	getCurrentTime,
	detailsInputHi,
	detailsInputEng,
} from "../../utils";
import { useTranslation, Trans } from "react-i18next";

const NAMEREGEX = /^(?=.*[a-zA-Z, ]).{3,}$/;
const MOBILEREGEX = /^[6-9]\d{9}$/; ///^(?=.*[6-9]).{1,10}$/;
const DetailsInputPage = () => {
	const history = useHistory();
	const { t } = useTranslation();

	const [userDetails, setUserDetails] = useState({});
	const [errors, setErrors] = useState({});
	const [initialState, setInitialState] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedAge, setselectedAge] = useState('');
	const [selectedTeacherType, setselectedTeacherType] = useState();
	const detailsContainerRef = useRef();

	const Ages = ["0 - 2 yrs", "2 - 5 yrs", "5 - 8 yrs", "> 8 yrs"];

	const teacherType = ["Full-Time Teacher", "Part-Time Teacher"];

	useEffect(() => {
		setInitialState(true);
	}, []);

	let lang = localStorage.getItem("lng");

	const languageHindi = lang == "hn" ? true : false;
	let staticTexts = languageHindi ? detailsInputHi : detailsInputEng;
	console.log(languageHindi, "++++langInput");

	function selectAge(value, index) {
		let Fb_obj = {
			0: "HealthScore_Teaching_Duration_0To2",
			1: "HealthScore_Teaching_Duration_2To5",
			2: "HealthScore_Teaching_Duration_5To8",
			3: "HealthScore_Teaching_Duration_Over8"
		};
		window.fbq('trackCustom', Fb_obj[value] );
		setselectedAge(value);
	}

	function selectTeacherType(value, index) {
		setselectedTeacherType(value);
		detailsContainerRef &&
			detailsContainerRef.current &&
			detailsContainerRef.current.scrollIntoView({ behavior: "smooth" });
	}

	const sendZapierData = () => {
		let utms = fetchUTMs();
		window.fbq('trackCustom',  'HealthScore_Continue');
		let responseTime = getCurrentTime();

		let body = {
			name: userDetails.name,
			experience: selectedAge,

			phoneNumber: userDetails.number,
			responseTime,
			...utms,
		};

		handleApiSendFn(body);
		setLoading(true);
		zapierService(body)
			.then((res) => {
				console.log(res, "+++_++++++");
				setLoading(false);
				localStorage.setItem("userDetails", JSON.stringify(body));
				history.push(`/questions?lang=${lang}`, body);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const LoadingText = languageHindi ? "धैर्य रखिये..." : "Please Wait...";
	//handling functions

	const handleApiSendFn = (payload) =>{

		let dataPayload = {...payload,
		type: 'plabs',
		campaignName: payload.utm_campaign,
      	utmSource: payload.utm_source,
      	utmMedium: payload.utm_medium,
		mobileNumber: payload.phoneNumber
		}

		fetch(
		  //'http://sales-incentive.classplus.co/newapi/saveonlineleads.php',
		  "https://crm.classplus.co/su/website",
		  {
			method: "POST",
			headers: {
			  "Content-Type": "application/json",
			},
			body: JSON.stringify(dataPayload),
		  }
		)
		  .then(
			//  res => console.log(res, 'palash reesp')
	
			//this.successPopUp(),
			// this.clearFormData()
		  )
		  .catch((err) => alert("Please Enter Valid Details"));
	
	  }

	const handleChange = (e) => {
		

		let { name, value, type } = e.target;
		if (type === "text") {
			value = value.replace(/[^a-zA-Z, ]/, "");
			window.fbq('trackCustom',  'HealthScore_Name_Entered');
		}
		if (type === "number") {
			if (value.length > 10) {
				window.fbq('trackCustom',  'HealthScore_Whatsapp_Number_Entered');
				value = value.substring(0, 10);
			}
		}
		setUserDetails((userDetails) => ({
			...userDetails,
			[name]: value,
		}));
		setErrors((errors) => ({
			...errors,
			[name]: "",
		}));
	};
	const handleBlur = (e) => {
		let { name, value } = e.target;
		let err = { ...errors };
		switch (name) {
			case "name":
				err[name] = NAMEREGEX.test(value) ? "" : "* Please enter your name";
				break;
			case "number":
				err[name] = MOBILEREGEX.test(value)
					? ""
					: "* Please enter your WhatsApp number. We promise not to spam you.";
				break;
			default:
				break;
		}
		setErrors({ ...err });
	};

	const handleOnClick=()=>{
		window.fbq('trackCustom',  'HealthScore_Details_Back');
	}

	return (
		<div className="details-container">
			<img
				src={backBtn}
				id="details-back-btn"
				onClick={
					() => {history.push("/")
							handleOnClick()}

				}
				alt="vendor"
			/>
			<h3 className="details-container-header">
				<Trans i18nKey="detailsInput.heading">
					First, we’d love to get to know a little bit about you!
				</Trans>
			</h3>

			<div className="details-details-card">
				<div className="details-input-text">
					<Trans i18nKey="detailsInput.question1">Name</Trans>
				</div>
				<input
					id="details-input-name"
					placeholder="eg. Harsh Srivastava"
					name="name"
					onChange={(e) => {
						handleChange(e);
					}}
					value={userDetails?.name}
					onBlur={handleBlur}
				/>
				{errors?.name ? (
					<span className="details-error">
						<Trans i18nKey="detailsInput.errorTextName">{errors?.name}</Trans>
					</span>
				) : (
					""
				)}
				{!initialState ? <></> : ""}

				<div className="Difference__Modal__Body__Input2">
					<div className="Difference__Modal__Body__Input--Text2">
						<Trans i18nKey="detailsInput.question2">Your WhatsApp number</Trans>
					</div>
					<div
						className="Difference__Modal__Body__Input__Container2"
						style={{ alignItems: "center" }}
					>
						<div className="Difference__Modal__Body__Input__Container--CountryCode2">
							+91-
						</div>
						<input
							onChange={(e) => {
								handleChange(e);
							}}
							placeholder="0000000000"
							value={userDetails?.number}
							type="number"
							name="number"
							maxLength="10"
							className="Difference__Modal__Body__Input__Container--Field2"
							onBlur={handleBlur}
						/>
					</div>
					<div className="details-error">
						{errors?.number ? (
							<span className="details-error">
								<Trans i18nKey="detailsInput.errorTextNumber">
									{errors?.number}
								</Trans>
							</span>
						) : (
							""
						)}
					</div>
				</div>

				{/* <div className="details-input-text">Type of Teacher</div>
				<div className="details-btn-group-2">
					{teacherType.map((type, index) => {
						return (
							<button
								className={
									selectedTeacherType == type
										? "selected-option"
										: "unselected-option"
								}
								key={index}
								value={type}
								onClick={(e) => {
									selectTeacherType(e.target.value);
								}}
								style={{ padding: "8px" }}
							>
								{type}
							</button>
						);
					})}
				</div> */}

				<div className="details-input-text">
					<Trans i18nKey="detailsInput.question3">
						For how long have you been teaching?
					</Trans>
				</div>
				<div
					className="details-btn-group-1"
					// onClick={(e)=>{setAge(e.target.value)}}
					ref={detailsContainerRef}
				>
					{[1,2,3,4].map((age, index) => {
						return (
							<button
								className={
									selectedAge == index ? "selected-option" : "unselected-option"
								}
								key={index}
								value={age}
								onClick={(e) => {
									selectAge(index);
								}}
								style={{ padding: "6px" }}
							>
								<Trans i18nKey={`ages.${index}.option`}></Trans>
							</button>
						);
					})}
				</div>

				<div
					className="type-error"
					style={
						teacherType
							? {
									display: "none",
							  }
							: {
									display: "block",
									color: "red",
									marginLeft: "5%",
									marginTop: "2%",
							  }
					}
				>
					* Please choose your Teaching status
				</div>

				<div className="details-container-bottom">
					{/* <div id="details-container-bottom-text">
						<Trans i18nKey="detailsInput.infoText">
							* It is a gender unbiased assessment. We treat both female and
							male educators equally!
						</Trans>
					</div> */}

					<button
						disabled={
							!(
								userDetails?.name &&
								userDetails?.name.length >= 3 &&
								userDetails?.number &&
								userDetails?.number.length === 10 &&
								selectedAge
							) || loading
						}
						onClick={sendZapierData}
						id="details-takeAssessmentCta"
					>
						{loading ? (
							LoadingText
						) : (
							<span>
								<Trans i18nKey="detailsInput.buttonText">Continue</Trans>
							</span>
						)}
					</button>
				</div>
			</div>
			<Modal />
		</div>
	);
};

export default DetailsInputPage;
