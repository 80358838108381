import React, { Component } from "react";
import Question from "./QuestionCard/";
import { question1, question1Hindi } from "../../utils";
import QuestionCard from "./QuestionCard";
import { useHistory } from "react-router-dom";
import zapierService from "../../service/zapier";

import "../../App.css";
class Question1 extends Component {
	constructor(props) {
		super(props);
		this.pageRef = React.createRef();
		let lang = localStorage.getItem("lng");

		const languageHindi = lang == "hn" ? true : false;
		this.state = {
			questions: languageHindi ? question1Hindi : question1,
		};
	}

	componentDidMount() {
		// console.log(languageHindi, "++++languageHindi");
		// this.setState({
		// 	questions: languageHindi ? [...question1Hindi] : [...question1],
		// });
	}

	onSelect = (
		questionNumber,
		selectedAnswerIndex,
		selectedText,

		isPreviouslySelected,
		isMultipleAnswers,
		answerWeightage
	) => {
		//spreading the current state
		let dummyStateQuestions = [...this.state.questions];
		let totalQuestions = this.state.questions.length;

		//copying already/previous selected value of the questionNumber 1.1 or 1.2 ... for easy to read code, will be put back later.
		let alreadySelectedValue =
			dummyStateQuestions[questionNumber].selectedValue;

		//questionWeightage
		let alreadyQuestionScore =
			dummyStateQuestions[questionNumber].questionScore;

		// think for the case of multiple answers
		if (isMultipleAnswers) {
			//toggle for multiple clicks on an option
			dummyStateQuestions[questionNumber].options[
				selectedAnswerIndex
			].isSelected = isPreviouslySelected ? 0 : 1;

			alreadyQuestionScore += answerWeightage;
			//appending more  into the alreadySelectedValue

			alreadySelectedValue += `${selectedText} & `;
		} else {
			//toggle for multiple clicks on an option
			dummyStateQuestions[questionNumber].options[
				selectedAnswerIndex
			].isSelected = isPreviouslySelected ? 0 : 1;

			//finding the index of the previous selected option/text;

			let previousSelectedOption = dummyStateQuestions[
				questionNumber
			].options.findIndex((option) => option.text == alreadySelectedValue);

			//turning off the previous selected answer
			if (previousSelectedOption >= 0) {
				dummyStateQuestions[questionNumber].options[
					previousSelectedOption
				].isSelected = 0;
			}

			//only single selection of value
			alreadySelectedValue = selectedText;

			

			//question score
			alreadyQuestionScore = answerWeightage;
		}

		//think of an if condition here,else code will break

		if (dummyStateQuestions[questionNumber + 1]) {
			dummyStateQuestions[questionNumber + 1].toShow = true;
			setTimeout(() => {
				this.props && this.props.scrollFunc();
			}, 100);
		}

		//putting the selectedValue back to the original array;
		dummyStateQuestions[questionNumber].selectedValue = alreadySelectedValue;
		dummyStateQuestions[questionNumber].questionScore = alreadyQuestionScore;

		this.setState({ question: { ...dummyStateQuestions } }, () => {
			console.log(this.state.questions, "selectedQuestions");

			const payloadQuestions = this.state.questions.map((question, index) => {
				return question.selectedValue;
			});
			

			if(payloadQuestions.includes("An Independent Teacher")){
				window.fbq('trackCustom',  'HealthScore_Q1_Independent_Teacher');
			}
			if(payloadQuestions.includes("Faculty at a coaching institute")){
				window.fbq('trackCustom',  'HealthScore_Q1_Faculty');
			}
			if(payloadQuestions.includes("Offline, in a Classroom & Online")){
				window.fbq('trackCustom',  'HealthScore_Q1_Offline');
			}
			if(payloadQuestions.includes("Online, on YouTube, Zoom, etc.")){
			
				window.fbq('trackCustom',  'HealthScore_Q1_Online_Ytzoom');
			}
			if(payloadQuestions.includes("Online, on Unacademy, Byju’s etc.")){
				window.fbq('trackCustom',  'HealthScore_Q1_Online_Edtech');
				
			}
			if(payloadQuestions.includes("Online, on my own app")){
				window.fbq('trackCustom',  'HealthScore_Q1_Online_App');
			}
			if(payloadQuestions.includes("Less than 500")){
				window.fbq('trackCustom',  'HealthScore_Q1_Less_Than_500');
			}
			if(payloadQuestions.includes("More than 500")){
				window.fbq('trackCustom',  'HealthScore_Q1_More_Than_500');
			}

			
				
			
			
			

			if (this.state.questions[totalQuestions - 1].selectedValue) {
				this.sendZapierData();
			}
		});
	};

	sendZapierData = () => {
		const { questions } = this.state;
		const { userDetails } = this.props;

		console.log("Make Payload", userDetails);
		const payloadQuestions = questions.map((question, index) => {
			return `Answer 1.${index + 1} -> ${question.selectedValue}`;
		});

		let totalScore = questions.reduce((acc, curr) => {
			return acc + curr.questionScore;
		}, 0);

		console.log(payloadQuestions, "payloadQuestions");

		const payloadFor1 = {
			...userDetails,
			question1: payloadQuestions.join("\n  "),
			question1Score: totalScore,
		};

		localStorage.setItem("question1Score", totalScore);

		zapierService(payloadFor1)
			.then((res) => {
				console.log(res, "+++_++++++ 2222");
				// history.push("/questions", body);
				this.props.navigation("next");
			})
			.catch((err) => {
				console.log(err);
			});

		//this.props.navigation("next");
		//
		//do your stuff here
	};

	render() {
		const { questions } = this.state;

		return (
			<div className="QuestionWrapper" ref={this.pageRef}>
				{/* <h1 style={{ color: "white" }}>QUESTION 1</h1> */}
				{questions.map((ques, index) => {
					return (
						<QuestionCard
							question={ques}
							key={index}
							source={index}
							onSelect={this.onSelect}
						/>
					);
				})}
			</div>
		);
	}
}

export default Question1;
