import React from "react";

import "./resultPage.css";
import { useTranslation, Trans } from "react-i18next";

const resultLoadingScreen = () => {
	return (
		<div
			className="LoadingScreen"
			style={{ color: "white", padding: "0 20px" }}
		>
			<div className="loader" />
			<Trans i18nKey="resultLoading.text">
				We are compiling the results, give us a few seconds.
			</Trans>
		</div>
	);
};

export default resultLoadingScreen;
