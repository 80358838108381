import React from "react";
import { useTranslation, Trans } from "react-i18next";

function ThankYou({ toggleThankYou, history }) {
	return (
		<div className="ThankYou">
			<div className="ThankYou__Content">
				<div className="ThankYou__Content__Top">
					<Trans i18nKey="thankyou.heading">Thank You</Trans>{" "}
				</div>
				<div className="ThankYou__Content__Body">
					<Trans i18nKey="thankyou.subheading">
						Our Sales Executive will reach out to you in the next 24 Hrs to help
						you grow your coaching Institute
					</Trans>{" "}
				</div>
				<div
					className="ThankYou__Content__Button"
					onClick={() => {
						history.push("/");
					}}
				>
					<Trans i18nKey="thankyou.button">Okay</Trans>{" "}
				</div>
			</div>
		</div>
	);
}

export default ThankYou;
