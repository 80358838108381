import React, { Component } from "react";

import QuestionCard from "./QuestionCard";
import { question2, question2Hindi } from "../../utils";
import zapierService from "../../service/zapier";
class Question2 extends Component {
	constructor(props) {
		super(props);
		this.pageRef = React.createRef();
		let lang = localStorage.getItem("lng");

		const languageHindi = lang == "hn" ? true : false;
		this.state = {
			questions: languageHindi ? question2Hindi : question2,
		};
	}

	onSelect = (
		questionNumber,
		selectedAnswerIndex,
		selectedText,

		isPreviouslySelected,
		isMultipleAnswers,
		answerWeightage
	) => {
		//spreading the current state
		let dummyStateQuestions = [...this.state.questions];
		let totalQuestions = this.state.questions.length;

		//copying already/previous selected value of the questionNumber 1.1 or 1.2 ... for easy to read code, will be put back later.
		let alreadySelectedValue =
			dummyStateQuestions[questionNumber].selectedValue;

		//questionWeightage
		let alreadyQuestionScore =
			dummyStateQuestions[questionNumber].questionScore;

		// think for the case of multiple answers
		if (isMultipleAnswers) {
			//toggle for multiple clicks on an option
			dummyStateQuestions[questionNumber].options[
				selectedAnswerIndex
			].isSelected = isPreviouslySelected ? 0 : 1;

			alreadyQuestionScore += answerWeightage;

			//appending more  into the alreadySelectedValue
			alreadySelectedValue += `${selectedText} & `;
		} else {
			//toggle for multiple clicks on an option
			dummyStateQuestions[questionNumber].options[
				selectedAnswerIndex
			].isSelected = isPreviouslySelected ? 0 : 1;

			//finding the index of the previous selected option/text;

			let previousSelectedOption = dummyStateQuestions[
				questionNumber
			].options.findIndex((option) => option.text == alreadySelectedValue);

			//turning off the previous selected answer
			if (previousSelectedOption >= 0) {
				dummyStateQuestions[questionNumber].options[
					previousSelectedOption
				].isSelected = 0;
			}

			//only single selection of value
			alreadySelectedValue = selectedText;

			//question score
			alreadyQuestionScore = answerWeightage;
		}

		//think of an if condition here,else code will break

		if (dummyStateQuestions[questionNumber + 1]) {
			dummyStateQuestions[questionNumber + 1].toShow = true;
			setTimeout(() => {
				this.props && this.props.scrollFunc();
			}, 100);
		}

		//putting the selectedValue back to the original array;
		dummyStateQuestions[questionNumber].selectedValue = alreadySelectedValue;
		dummyStateQuestions[questionNumber].questionScore = alreadyQuestionScore;

		this.setState({ question: { ...dummyStateQuestions } }, () => {
			console.log(this.state.questions, "selectedQuestions");

			const payloadQuestions = this.state.questions.map((question, index) => {
				return question.selectedValue;

				
			});

			if(payloadQuestions.includes("Shut down completely")){
				window.fbq('trackCustom',  'HealthScore_Q2_Shut_Down');
			}
			if(payloadQuestions.includes("Continued running, but slowed down")){
				window.fbq('trackCustom',  'HealthScore_Q2_Slowed_Down');
			}
			if(payloadQuestions.includes("Students/Income increased")){
				window.fbq('trackCustom',  'HealthScore_Q2_Income_Increased');
			}
			if(payloadQuestions.includes("Remained same")){
				window.fbq('trackCustom',  'HealthScore_Q2_Income_Same');
			}

			if(payloadQuestions.includes("Continuing Offline, in a Classroom")){
				window.fbq('trackCustom',  'HealthScore_Q2_Continue_Offline');
			}
			if(payloadQuestions.includes("50% Offline and 50% Online")){
				window.fbq('trackCustom',  'HealthScore_Q2_50_Online_50_Offline');
			}
			if(payloadQuestions.includes("20% Offline and 80% Online")){
				window.fbq('trackCustom',  'HealthScore_Q2_80_Online_20_Offline');
			}
			if(payloadQuestions.includes("Have shifted completely Online")){
				window.fbq('trackCustom',  'HealthScore_Q2_Complete_Online');
			}


			if(payloadQuestions.includes("Yes, it has increased")){
				window.fbq('trackCustom',  'HealthScore_Q2_Students_Increased');
			}
			if(payloadQuestions.includes("No, it has decreased")){
				window.fbq('trackCustom',  'HealthScore_Q2_Students_Decreased');
			}
			if(payloadQuestions.includes("Remained more or less same")){
				window.fbq('trackCustom',  'HealthScore_Q2_Students_Same');
			}


			if(payloadQuestions.includes("It has increased")){
				window.fbq('trackCustom',  'HealthScore_Q2_Lockdown_Income_Increased');
			}
			if(payloadQuestions.includes("It has decreased")){
				window.fbq('trackCustom',  'HealthScore_Q2_Lockdown_Income_Decreased');
			}
			if(payloadQuestions.includes("Remained more or less same")){
				window.fbq('trackCustom',  'HealthScore_Q2_Lockdown_Income_Same');
			}

			if (this.state.questions[totalQuestions - 1].selectedValue) {
				this.sendZapierData();
			}
		});
	};

	sendZapierData = () => {
		const { questions } = this.state;
		const { userDetails } = this.props;

		console.log("Make Payload", userDetails);
		const payloadQuestions = questions.map((question, index) => {
			return `Answer 2.${index + 1} -> ${question.selectedValue}`;
		});

		console.log(payloadQuestions, "payloadQuestions");

		let totalScore = questions.reduce((acc, curr) => {
			return acc + curr.questionScore;
		}, 0);

		const payloadFor2 = {
			question2: payloadQuestions.join("\n  "),
			...userDetails,
			question2Score: totalScore,
		};

		localStorage.setItem("question2Score", totalScore);
		zapierService(payloadFor2)
			.then((res) => {
				console.log(res, "+++_++++++ 2222");
				// history.push("/questions", body);
				this.props.navigation("next");
			})
			.catch((err) => {
				console.log(err);
			});

		//this.props.navigation("next");
		//
		//do your stuff here
	};

	render() {
		const { questions } = this.state;
		return (
			<div className="QuestionWrapper" ref={this.pageRef}>
				{/* <h1 style={{ color: "white" }}>QUESTION 2</h1>
				<h1 style={{ color: "white" }}>QUESTION 1</h1> */}
				{questions.map((ques, index) => {
					return (
						<QuestionCard
							question={ques}
							key={index}
							source={index}
							onSelect={this.onSelect}
						/>
					);
				})}
			</div>
		);
	}
}

export default Question2;
