import { React, useState } from "react";
import "./sliderCard.css";

const convertedToINR = (x) => {
	x = x.toString();
	var lastThree = x.substring(x.length - 3);
	var otherNumbers = x.substring(0, x.length - 3);
	if (otherNumbers != "") lastThree = "," + lastThree;
	var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
	return res;
};

const sliderEnglishQues1 = () => {
	return (
		<div>
			One of the Strongest branding you can do is to have a Mobile App of your
			own Coaching Institute Listed on Google Play Store
			<br /> <br />
			How willing are you to have your own Branded Coaching App & teach students
			across India?
		</div>
	);
};

const sliderHindiQues1 = () => {
	return (
		<div>
			इससे अच्छी ब्रांडिंग क्या होगी की आपके कोचिंग की अपनी मोबाइल ऐप Google
			Play Store में आपके छात्रों को दिखे।
			<br /> <br />
			आप अपने खुद के ब्रांडेड कोचिंग ऐप होने और पूरे भारत में छात्रों को पढ़ाने
			के लिए कितने इच्छुक हैं?
		</div>
	);
};

const sliderHindiQues2 = () => {
	return `यदि आप खुद के कोचिंग ऐप से पढ़ाते हैं, तो आप अपनी मासिक आय क्या होने की उम्मीद रखतेे हैं?`;
};

const sliderEnglishQues2 = () => {
	return `What do you expect your monthly Income to be, in case you teach through your own Coaching App ?`;
};

const SliderCard = (props) => {
	const [sliderValue, setsliderValue] = useState(0);
	const { languageHindi } = props;
	let sliderWidth =
		props.question == 1 ? props.sliderValue : props.sliderValue / 10000;

	console.log(sliderWidth, "+++++sliderWidth");
	return (
		<div className="card-container">
			<div className="slider-question">
				{props.question == 1
					? languageHindi
						? sliderHindiQues1()
						: sliderEnglishQues1()
					: languageHindi
					? sliderHindiQues2()
					: sliderEnglishQues2()}
			</div>

			<div
				className="MultpleNote"
				style={{ textAlign: "center", marginTop: "12px" }}
			>
				*Drag the slider to adjust value
			</div>

			<div className="slider-value-display">
				{props.question == 1 ? "" : "₹"}
				{props.question == 1
					? props.sliderValue
					: convertedToINR(props.sliderValue > 0 ? props.sliderValue : 100000)}
				{props.question == 1 ? "%" : " /-"}
			</div>
			<div className="inputContainer">
				<input
					type="range"
					min={props.question == 1 ? "0" : "100000"}
					max={props.question == 1 ? "100" : "1000000"}
					defaultValue="0"
					value={props.sliderValue}
					onChange={(e) => props.sliderChange(e.target.value, props.question)}
					onClick={(e) => props.sliderChange(e.target.value, props.question)}
					onTouchEnd={() => props.toggleSeeNext()}
					className="question-slider"
				/>

				<div className="FillerBar">
					<div
						className="Filler"
						style={{
							width: `calc(${sliderWidth}% - 4px)`,
						}}
					/>
				</div>
				{/* <div
          className="FilledState"
          style={{ width: `${props.sliderValue}%` }}
        ></div>
        <div className="CoverDiv" /> */}
			</div>

			<div className="question-slider-values">
				<div
					className={
						props.question == 1
							? "question-slider-value-span"
							: "question-slider-value-span-2"
					}
				>
					<span>|</span>
					{props.question == 1 ? (
						<span>0</span>
					) : (
						<span>{convertedToINR(100000)}</span>
					)}
				</div>
				<div
					className={
						props.question == 1
							? "question-slider-value-span"
							: "question-slider-value-span-3 "
					}
					style={{ paddingRight: "10px" }}
				>
					<span>|</span>
					{props.question == 1 ? (
						<span>100</span>
					) : (
						<span style={{ paddingRight: "5px" }}>
							{convertedToINR(1000000)}
						</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default SliderCard;
