export const question1 = [
	{
		question:
			"Are you an Independent Teacher or Faculty at any coaching institute?",
		options: [
			{
				text: "An Independent Teacher",
				isSelected: 0,
				answerWeightage: 0,
			},
			{
				text: "Faculty at a coaching institute",
				isSelected: 0,
				answerWeightage: 0,
			},
		],
		selectedValue: "",
		toShow: true,
		isMultiple: false,
		questionScore: 0,
	},

	{
		question: "How were you teaching before COVID?",
		options: [
			{
				text: "Offline, in a Classroom",
				isSelected: 0,
				answerWeightage: 0,
			},
			{
				text: "Online, on YouTube, Zoom, etc.",
				isSelected: 0,
				answerWeightage: 2,
			},

			{
				text: "Online, on Unacademy, Byju’s etc.",
				isSelected: 0,
				answerWeightage: 3,
			},
			{
				text: "Online, on my own app",
				isSelected: 0,
				answerWeightage: 4,
			},
		],
		selectedValue: "",
		toShow: false,
		isMultiple: true,
		questionScore: 0,
	},
	{
		question: "How many students were you teaching before the first lockdown?",
		options: [
			{
				text: "Less than 500",
				isSelected: 0,
				answerWeightage: 3,
			},
			{
				text: "More than 500",
				isSelected: 0,
				answerWeightage: 5,
			},
		],
		selectedValue: "",
		toShow: false,
		isMultiple: false,
		questionScore: 0,
	},
];

export const question2 = [
	{
		question: "What impact did COVID have on your Coaching Institute?",
		options: [
			{
				text: "Shut down completely",
				isSelected: 0,
				answerWeightage: 0,
			},
			{
				text: "Continued running, but slowed down",
				isSelected: 0,
				answerWeightage: 4,
			},
			{
				text: "Students/Income increased",
				isSelected: 0,
				answerWeightage: 8,
			},
			{
				text: "Remained same",
				isSelected: 0,
				answerWeightage: 0,
			},
		],
		selectedValue: "",
		toShow: true,
		isMultiple: false,
		questionScore: 0,
	},

	{
		question: "How are you teaching now?",
		options: [
			{
				text: "Continuing Offline, in a Classroom",
				isSelected: 0,
				answerWeightage: 0,
			},
			{
				text: "50% Offline and 50% Online",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "20% Offline and 80% Online",
				isSelected: 0,
				answerWeightage: 4,
			},
			{
				text: "Have shifted completely Online",
				isSelected: 0,
				answerWeightage: 6,
			},
		],
		selectedValue: "",
		toShow: false,
		isMultiple: false,
		questionScore: 0,
	},
	{
		question: "Has the number of students you teach increased?",
		options: [
			{
				text: "Yes, it has increased",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "No, it has decreased",
				isSelected: 0,
				answerWeightage: 0,
			},
			{
				text: "Remained more or less same",
				isSelected: 0,
				answerWeightage: 1,
			},
		],
		selectedValue: "",
		toShow: false,
		isMultiple: false,
		questionScore: 0,
	},
	{
		question: "Has your monthly income changed during the lockdown?",
		options: [
			{
				text: "It has increased",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "It has decreased",
				isSelected: 0,
				answerWeightage: 0,
			},
			{
				text: "Remained more or less same",
				isSelected: 0,
				answerWeightage: 1,
			},
		],
		selectedValue: "",
		toShow: false,
		isMultiple: false,
		questionScore: 0,
	},
];

export const question3 = [
	{
		question: "Have you heard about Byju’s, Unacademy, Vedantu, etc.?",
		options: [
			{
				text: "Yes, I want to become like them one day",
				isSelected: 0,
				answerWeightage: 4,
			},
			{
				text: "Yes, but not worried",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "No, I don’t know anything about them",
				isSelected: 0,
				answerWeightage: 0,
			},
		],
		selectedValue: "",
		toShow: true,
		isMultiple: false,
		questionScore: 0,
	},
	{
		question: "Choose what all you have done to grow your students count.?",
		options: [
			{
				text: "Ask students to refer more students.",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "Pamphlets, newspaper ads, etc.",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "YouTube, Telegram, Facebook, Instagram pages",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "Trial classes, scholarship tests, etc.",
				isSelected: 0,
				answerWeightage: 2,
			},
		],
		selectedValue: "",
		toShow: false,
		isMultiple: true,
		questionScore: 0,
		isSkip: true,
	},
	{
		question:
			"Are you thinking about your Own Coaching's Branding & marketing?",
		options: [
			{
				text: "Yes, I want to learn how to get more students",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "Yes, I want to make it a big brand like Byju’s",
				isSelected: 0,
				answerWeightage: 4,
			},
			{
				text: "No, I am doing well",
				isSelected: 0,
				answerWeightage: 0,
			},
		],
		selectedValue: "",
		toShow: false,
		isMultiple: false,
		questionScore: 0,
	},
];

export const question4 = [
	{
		question:
			"What do you think your monthly income would be like in case you had your own coaching android application?",
		options: [
			{
				text: "Less than what I’m earning right now",
				isSelected: 0,
				answerWeightage: 0,
			},
			{
				text: "More or less will remain the same",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "Flourish and grow more than 2x",
				isSelected: 0,
				answerWeightage: 6,
			},
			{
				text: "Cannot say",
				isSelected: 0,
				answerWeightage: 0,
			},
		],
		selectedValue: "",
		toShow: true,
		isMultiple: false,
		questionScore: 0,
	},
];

export const fetchUTMs = () => {
	const utms = {
		utm_source: localStorage.getItem("utm_source")
			? localStorage.getItem("utm_source")
			: "",
		utm_medium: localStorage.getItem("utm_medium")
			? localStorage.getItem("utm_medium")
			: "",
		utm_campaign: localStorage.getItem("utm_campaign")
			? localStorage.getItem("utm_campaign")
			: "",
	};

	return utms;
};

export const getCurrentTime = () => {
	var currentTime = new Date();
	var currentDate = new Date().getDate();
	var currentMonth = new Date().getMonth();

	var currentOffset = currentTime.getTimezoneOffset();

	var ISTOffset = 330; // IST offset UTC +5:30

	var ISTTime = new Date(
		currentTime.getTime() + (ISTOffset + currentOffset) * 60000
	);

	// ISTTime now represents the time in IST coordinates

	var hoursIST = ISTTime.getHours();
	var minutesIST = ISTTime.getMinutes();

	return `Date - ${currentDate}/${currentMonth}  Time - ${hoursIST} hrs : ${minutesIST} mins`;
};

export const welcomePageTextsEng = {
	heading: "Know your Coaching's Health Score",
	subheading: `Take this free coaching health assessment and know where your
	Educational institute stands amongst the other{" "}
	1,000,000  educators of
	India?`,
	buttonText: `Start Now`,
};

export const welcomePageTextsHi = {
	heading: "जानिए अपने कोचिंग का Health Score",
	subheading: `यह निःशुल्क कोचिंग Health Assessment लें और जानें कि भारत के अन्य 1,000,000 शिक्षकों में आपका शैक्षणिक संस्थान कहां है`,
	buttonText: `अभी शुरू करें`,
};

export const detailsInputEng = {
	heading: "First, we’d love to get to know a little bit about you!",
	question1: `Name`,
	question2: `Your WhatsApp Number`,
	question3: `For how long have you been teaching?`,
	question3options: [
		{
			option: `0 - 2 yrs`,
		},
		{
			option: `2 - 5 yrs`,
		},
		{
			option: `5 - 8 yrs`,
		},
		{
			option: `> 8 yrs`,
		},
	],
	infoText: `* It is a gender unbiased assessment. We treat both female and male eduactors equally!`,
	buttonText: `Continue`,
	errorTextName: `* Please enter your name`,
	errorTextNumber: `* Please enter your WhatsApp number. We promise not to spam you.`,
	errorExperience: `* Please select your years of experience`,
};

export const detailsInputHi = {
	heading: "सबसे पहले, हमें आपके बारे में कुछ जानना अच्छा लगेगा!",
	question1: `आपका नाम`,
	question2: `आपका WhatsApp नंबर`,
	question3: `आप कितने समय से पढ़ा रहे हैं?`,
	question3options: [
		{
			option: `0 - 2 साल`,
		},
		{
			option: `2 - 5 साल`,
		},
		{
			option: `5 - 8 साल`,
		},
		{
			option: `> 8 साल`,
		},
	],
	infoText: `* यह एक लिंग निष्पक्ष मूल्यांकन है। हम महिला और पुरुष दोनों शिक्षकों के साथ समान व्यवहार करते हैं!`,
	buttonText: `जारी रखें`,
	errorTextName: `* अपना नाम दर्ज करें`,
	errorTextNumber: `*  कृपया अपना WhatsApp नंबर दर्ज करें। हम आपको स्पैम नहीं करने का वादा करते हैं।`,
	errorExperience: `* कृपया अपने वर्षों के अनुभव का चयन करें`,
};

export const question1Hindi = [
	{
		question: "क्या आप किसी कोचिंग मे फैकल्टी हैं या आपकी अपनी कोचिंग हैं?",
		options: [
			{
				text: "अपनी खुद की कोचिंग है ",
				isSelected: 0,
				answerWeightage: 0,
			},
			{
				text: "एक कोचिंग संस्थान में फैकल्टी है ",
				isSelected: 0,
				answerWeightage: 0,
			},
		],
		selectedValue: "",
		toShow: true,
		isMultiple: false,
		questionScore: 0,
	},

	{
		question: "आप COVID-19 से पहले कैसे पढ़ा रहे थे?",
		options: [
			{
				text: "Offline,  कक्षा में",
				isSelected: 0,
				answerWeightage: 0,
			},
			{
				text: "Online, YouTube, Zoom आदि पर",
				isSelected: 0,
				answerWeightage: 2,
			},

			{
				text: "Online, Unacademy, Byju’s आदि पर",
				isSelected: 0,
				answerWeightage: 3,
			},
			{
				text: "Online, अपने खुद के मोबाइल ऐप पर",
				isSelected: 0,
				answerWeightage: 4,
			},
		],
		selectedValue: "",
		toShow: false,
		isMultiple: true,
		questionScore: 0,
	},
	{
		question: "पहले लॉकडाउन से पहले आप कितने छात्रों को पढ़ा रहे थे?",
		options: [
			{
				text: "500 से कम",
				isSelected: 0,
				answerWeightage: 3,
			},
			{
				text: "500 से ज़्यादा",
				isSelected: 0,
				answerWeightage: 5,
			},
		],
		selectedValue: "",
		toShow: false,
		isMultiple: false,
		questionScore: 0,
	},
];

export const question2Hindi = [
	{
		question: "आपके कोचिंग संस्थान पर COVID का क्या असर पड़ा है?",
		options: [
			{
				text: "पूरी तरह से बंद हो गयी",
				isSelected: 0,
				answerWeightage: 0,
			},
			{
				text: "कक्षाएं चलती रहीं, लेकिन आय कम हो गईं",
				isSelected: 0,
				answerWeightage: 4,
			},
			{
				text: "छात्र/आय में वृद्धि हो गईं",
				isSelected: 0,
				answerWeightage: 8,
			},
			{
				text: "समान रहा",
				isSelected: 0,
				answerWeightage: 0,
			},
		],
		selectedValue: "",
		toShow: true,
		isMultiple: false,
		questionScore: 0,
	},

	{
		question: "अब आप कैसे पढ़ा रहे हैं?",
		options: [
			{
				text: "कक्षा  में, Offline ही",
				isSelected: 0,
				answerWeightage: 0,
			},
			{
				text: "50% Offline, 50% Online",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "20% Offline, 80% Online",
				isSelected: 0,
				answerWeightage: 4,
			},
			{
				text: "पूरी तरह से Online शिफ्ट हो गए हैं",
				isSelected: 0,
				answerWeightage: 6,
			},
		],
		selectedValue: "",
		toShow: false,
		isMultiple: false,
		questionScore: 0,
	},
	{
		question: "क्या आपके छात्रों की संख्या बढ़ गयी है?",
		options: [
			{
				text: "हाँ, बढ़ गयी है",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "नहीं, घट गयी है",
				isSelected: 0,
				answerWeightage: 0,
			},
			{
				text: "समान रही",
				isSelected: 0,
				answerWeightage: 1,
			},
		],
		selectedValue: "",
		toShow: false,
		isMultiple: false,
		questionScore: 0,
	},
	{
		question: "क्या लॉकडाउन के दौरान आपकी मासिक आय में बदलाव आया है?",
		options: [
			{
				text: "बढ़ गयी है",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "घट गयी है",
				isSelected: 0,
				answerWeightage: 0,
			},
			{
				text: "समान रही",
				isSelected: 0,
				answerWeightage: 1,
			},
		],
		selectedValue: "",
		toShow: false,
		isMultiple: false,
		questionScore: 0,
	},
];

export const question3Hindi = [
	{
		question: "क्या आपने Byju’s, Unacademy, Vedantu आदि के बारे में सुना है?",
		options: [
			{
				text: "हाँ, मैं एक दिन उनके जैसा बनना चाहता हूँ",
				isSelected: 0,
				answerWeightage: 4,
			},
			{
				text: "हाँ, लेकिन चिंतित नहीं हैं",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "नहीं, मैं उनके बारे में कुछ नहीं जानता",
				isSelected: 0,
				answerWeightage: 0,
			},
		],
		selectedValue: "",
		toShow: true,
		isMultiple: false,
		questionScore: 0,
	},
	{
		question: "आपने अपने छात्रों की संख्या को बढ़ाने के लिए क्या क्या किया है?",
		options: [
			{
				text: "छात्रों से और छात्रों को Refer करने के लिए कहा हैैं।",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "पैम्फलेट, अखबार के विज्ञापन आदि।",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "Youtube, Telegram, Facebook, Instagram पेज",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "Trial Classes, scholarship test",
				isSelected: 0,
				answerWeightage: 2,
			},
		],
		selectedValue: "",
		toShow: false,
		isMultiple: true,
		questionScore: 0,
		isSkip: true,
	},
	{
		question:
			"क्या आप अपनी खुद की कोचिंग की ब्रांडिंग और मार्केटिंग के बारे में सोच रहे हैं?",
		options: [
			{
				text: "हां, मैं सीखना चाहता हूं कि और नए छात्र कैसे लाये",
				isSelected: 0,
				answerWeightage: 2,
			},
			{
				text: "हां, मैं इसे Byju’s की तरह एक बड़ा ब्रांड बनाना चाहता हूं",
				isSelected: 0,
				answerWeightage: 4,
			},
			{
				text: "नहीं, मैं अच्छा कर रहा हूँ",
				isSelected: 0,
				answerWeightage: 0,
			},
		],
		selectedValue: "",
		toShow: false,
		isMultiple: false,
		questionScore: 0,
	},
];
