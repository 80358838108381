import React, { useEffect, useState } from "react";
import "./HomePage2.css";

import { welcomePageTextsEng, welcomePageTextsHi } from "../../utils";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

import classplusLogo from "../../resources/NewHomepages/Homepage2/classplus.png";
import SG from "../../resources/NewHomepages/Homepage2/SG.png";

import Bullet from "../../resources/NewHomepages/Homepage2/bullet.png";

const HomePage2 = () => {

    useEffect(() => {
		const vars = {};
		window.location.href.replace(
			/[?&]+([^=&]+)=([^&]*)/gi,
			function (m, key, value) {
				vars[key] = value;
			}
		);

		localStorage.setItem("utm_source", vars["utm_source"]);
		localStorage.setItem("utm_medium", vars["utm_medium"]);
		localStorage.setItem("utm_campaign", vars["utm_campaign"]);
		// setLang(vars["lang"]);
		// localStorage.setItem("lng", language);
		// props.i18n.changeLanguage(language);
	}, []);

	let lang = localStorage.getItem("lng");

	const languageHindi = lang == "hn" ? true : false;
	let staticTexts = languageHindi ? welcomePageTextsHi : welcomePageTextsEng;
	console.log(lang, "+++++welcome");


    return (
        <div className="homepage2-container">
            <img src={classplusLogo} id="homepage2-classplus-logo"/>
            <div className="homepage2-top">
                
                <img src={SG} id="homepage2-SG"/>
                <div className="homepage2-text-sg">
                <div className="homepage2-top-text">
                    <p style={{fontSize:"17px",fontStyle: "italic", marginBottom:"5px"}}>“Assessment is the first step to improvement!”</p>
                    <span style={{fontSize:"16px"}}>-Sourav Ganguly</span>
                    <span style={{fontSize:"12px", opacity:"0.6"}}>MENTOR DADA</span>
                </div>
                
                </div>


            </div>   

            <div className="homepage2-bottom">
                <div className="homepage2-bottom-header">
                <h1>Your coaching can be the best in the town  🙌🏻</h1>
                </div>
                <div className="homepage2-bottom-content">


                <div className="homepage2-bottom-text">

               
                <p style={{fontSize:"12px", marginBottom:"5px",color: "rgba(255, 255, 255, 0.87)"}}>TAKE THIS ASSESSMENT TO KNOW</p>
                <div className="homepage2-bulletpoints">
                    <img src={Bullet}/><span className="homepage2-bullets">Know how, in less than a minute</span>
                </div>
                <div className="homepage2-bulletpoints">
                    <img src={Bullet}/><span>Get crucial insights which will increase your growth rate </span>
                </div>
                </div>

                <div className="homepage2-CTA-div">

                <Link style={{ width: "100%", display:"flex",flexDirection:"row",justifyContent:"center" }} to={`/details?lang=${lang}`}> 
                <button id="homepage2-CTA">
                Take your free assesment
                <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_3lrvgesy.json"  speed="1" id="lottie-black" loop  autoplay></lottie-player>
                </button>
                </Link>
                </div>
                </div>
            </div>          

        </div>
    )
}

export default HomePage2
