import React from "react";

import Sourav from "../../resources/Sourav.png";
import Close from "../../resources/Close.svg";
import zapierService from "../../service/zapier";
import { useTranslation, Trans } from "react-i18next";

function DifferenceModal(props) {
	const { closeAction, toggleThankYou } = props;

	const sendZapierData = () => {
		const localUserDetails = JSON.parse(localStorage.getItem("userDetails"));
		const payload = {
			didRequestedDemo: "yes",
			...localUserDetails,
		};

		zapierService(payload)
			.then((res) => {
				console.log(res, "+++_++++++");
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleOnClick=()=>{
		window.fbq('trackCustom',  'HealthScore_Request_A_Demo');
	}
	return (
		<div className="Difference">
			<div className="Difference__Modal">
				<div className="Difference__Modal__Top">
					<img
						className="Difference__Modal__Top--Sourav"
						src={Sourav}
						alt="Sourav"
					/>
					<img
						onClick={closeAction}
						className="Difference__Modal__Top--Close"
						src={Close}
						alt="Sourav"
					/>
				</div>
				<div className="Difference__Modal__Body">
					<div className="Difference__Modal__Body__Text">
						<div className="Difference__Modal__Body__Text--Heading">
							<Trans i18nKey="difference.heading">
								I recommend checking Classplus!
							</Trans>{" "}
						</div>
						<div className="Difference__Modal__Body__Text--SubHeading">
							<Trans i18nKey="difference.subheading">
								We at Classplus are empowering educators across India by making
								accessible to conduct live classes, take attendance, give tests,
								send a message, and two dozen other services which an educator
								like you might want in
							</Trans>{" "}
							<b>
								<Trans i18nKey="difference.subheadingbold">his own app.</Trans>
							</b>
							<br />
							<br />
							<b>
								<Trans i18nKey="difference.doubt">
									Still in doubt? Take a free demo and see how you can grow with
									us.
								</Trans>
							</b>
						</div>
					</div>
					<div className="Difference__Modal__Body__Input">
						<div className="Difference__Modal__Body__Input--Text"></div>
						{/* <div className="Difference__Modal__Body__Input__Container">
              <div className="Difference__Modal__Body__Input__Container--CountryCode">
                +91-
              </div>
              <input
                placeholder="0000000000"
                type="number"
                maxLength="10"
                className="Difference__Modal__Body__Input__Container--Field"
              />
            </div> */}
					</div>
					<div className="Difference__Modal__Body__Bottom">
						<div
							className="Difference__Modal__Body__Bottom--Button"
							onClick={() => {
								sendZapierData();
								toggleThankYou();
							}}
						>
							<Trans i18nKey="difference.button"
							onClick={()=>{
								handleOnClick()
							}}
							>Request a demo</Trans>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DifferenceModal;
