import React, { useEffect, useState } from "react";
import "./resultPage.css";

import backBtn from "../../resources/backBtn.png";
import CustomModal from "../Modal";
import DifferenceModal from "./DifferenceModal";
import { useLocation } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import ResultLoading from "./ResultLoading";
import healthScoreFormula from "./healthScoreFormula";
import ThankYou from "./ThankYou";
import zapierService from "../../service/zapier";
import { useTranslation, Trans } from "react-i18next";

const ResultPage = (props) => {
	const history = useHistory();

	//useState empty what?
	const [resultSliderValue, setresultSliderValue] = useState();
	const [showLoadingScreen, setShowLoadingScreen] = useState(true);
	const [showThankYou, setShowThankYou] = useState(false);
	const [showDifferenceModal, updateShowDifferenceModal] = useState(false);
	const [zoneValue, setZonalValue] = useState("Yellow");
	let location = useLocation();
	let userDetails = { ...location.state };
	let localUserDetails = JSON.parse(localStorage.getItem("userDetails"));

	const toggleThankYou = () => {
		updateShowDifferenceModal(false);
		setShowThankYou(!showThankYou);
	};

	if (!Object.keys(userDetails).length || !userDetails.name) {
		userDetails = { ...localUserDetails };
	}
	let name = userDetails && userDetails.name;

	useEffect(() => {
		window.fbq('trackCustom',  'HealthScore_Overlay_Loaded');
		setTimeout(() => {
			setShowLoadingScreen(false);
			window.fbq('trackCustom',  'HealthScore_Overlay_Closed');
		}, 2000);

		setTimeout(() => {
			updateShowDifferenceModal(true);
		}, 32000);

		//set compiled value and make many checks on basis of that
		let compiledValue = healthScoreFormula();
		setresultSliderValue(compiledValue);
		setZonalValue(compiledValue > 27 ? "Yellow" : "Red");
		window.fbq('trackCustom',  'HealthScore_Results_Pageloaded');

	}, []);

	useEffect(() => {
		setTimeout(() => {
			updateShowDifferenceModal(true);
		}, 30 * 1000);
	}, []);

	console.log(showLoadingScreen, "showLoadingScreen");

	const handleSendOnWhatsapp = () => {
		const url = window.location.origin;

		const whatsappTesxt = `I took Classplus's coaching health check and figured out where my coaching stands amongst *1,000,000* other educators of India. I am in the *${zoneValue} Zone*.
    I invite you to take this assessment and check out your coaching's health score. \n ${url} `;

		window.open(
			`https://api.whatsapp.com/send?text=${whatsappTesxt}`,
			"_blank"
		);
	};

	const sendZapierData = () => {
		const localUserDetails = JSON.parse(localStorage.getItem("userDetails"));
		const payload = {
			didClickDifference: "yes",
			...localUserDetails,
		};

		zapierService(payload)
			.then((res) => {
				console.log(res, "+++_++++++");
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleOnClick = ()=>{
		window.fbq('trackCustom',  'HealthScore_Share_On_Whatsapp');
	}


	return showLoadingScreen ? (
		<ResultLoading />
	) : (
		//make the formula and zone wise checks
		<>
			<div className="result-container">
				<div style={{ marginBottom: "48px" }}>
					<img
						src={backBtn}
						id="details-back-btn"
						onClick={() =>{ history.push("/questions", { showQuestion4: true })
						window.fbq('trackCustom',  'HealthScore_Results_Back');
					}}
					/>
					<h2 className="result-container-header">
						<span style={{ textTransform: "capitalize" }}>{name}</span>,{" "}
						<Trans i18nKey="results.heading">
							your coaching institute is in the
						</Trans>{" "}
						<span
							style={{ color: zoneValue == "Yellow" ? "#E6F974" : "#FF6D6D" }}
						>
							{zoneValue} Zone
						</span>{" "}
						<Trans i18nKey="results.zone"></Trans>
					</h2>
					<p className="result-head-text">
						<Trans i18nKey="results.motiv">
							You have everything you need to be successful, it’s just you need
							to put in some extra efforts to increase your chances to succeed.
						</Trans>{" "}
					</p>
				</div>

				<div className="result-details-card">
					<div className="result-details-card-head">
						<p id="result-p1">
							<Trans i18nKey="results.healthScore">
								Coaching's Health Score
							</Trans>{" "}
						</p>
						<p
							id="result-p2"
							onClick={() => {
								updateShowDifferenceModal(true);
								sendZapierData();
							}}
						>
							<Trans i18nKey="results.difference">Classplus's Difference</Trans>{" "}
						</p>
					</div>

					<div id="result-slider-value">{resultSliderValue}%</div>
					<div className="inputContainer">
						<input
							disabled={true}
							type="range"
							min="0"
							max="100"
							value={resultSliderValue}
							onChange={(e) => setresultSliderValue(e.target.value)}
							className="result-slider"
						/>
						<div className="FillerBar">
							<div
								className="Filler"
								style={{ width: `calc(${resultSliderValue}% - 4px)` }}
							/>
						</div>
					</div>

					<div className="result-slider-values">
						<div className="result-slider-value-span">
							<span>|</span>
							<span>0</span>
						</div>
						<div className="result-slider-value-span">
							<span>|</span>
							<span>100</span>
						</div>
					</div>

					<div className="result-details-card-text">
						<Trans i18nKey="results.percentage"></Trans>{" "}
						{100 - resultSliderValue}%{" "}
						<Trans i18nKey="results.percentageInfo">
							Coaching Institutes in India are ahead of you & have adopted
							Modern techniques by having their own Coaching App.
						</Trans>
					</div>

					<div className="result-container-bottom">
						<div id="result-container-bottom-text">
							<Trans i18nKey="results.encourage">
								Found it useful? Encourage your friends to take it too!
							</Trans>
						</div>
						<button id="takeAssessmentCta" onClick={handleSendOnWhatsapp}>
							<Trans i18nKey="results.shareLink"
							onClick={()=>{
								handleOnClick()
							}}
							>Share link on WhatsApp</Trans>
						</button>
					</div>
				</div>
			</div>
			{showDifferenceModal && (
				<DifferenceModal
					closeAction={() => updateShowDifferenceModal(false)}
					toggleThankYou={toggleThankYou}
				/>
			)}
			{showThankYou && (
				<ThankYou toggleThankYou={toggleThankYou} history={history} />
			)}
		</>
	);
};

export default ResultPage;
