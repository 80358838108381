import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import Questions from "./components/Questions";

import "semantic-ui-css/semantic.min.css";

import WelcomePage from "./components/WelcomePage";
import InstructionPage from "./components/InstructionsPage";
import DetailsInputPage from "./components/DetailsInputPage";
import ResultPage from "./components/ResultPage";
import { translate, Trans, withTranslation } from "react-i18next";

import { BrowserRouter, Route, Switch, BrowserHistory } from "react-router-dom";

import HomePage1 from "./components/HomePage1";
import HomePage2 from "./components/HomePage2";
import HomePage3 from "./components/HomePage3";

function App(props) {
	useEffect(() => {
		const vars = {};
		window.location.href.replace(
			/[?&]+([^=&]+)=([^&]*)/gi,
			function (m, key, value) {
				vars[key] = value;
			}
		);

		let language = vars["lang"];

		localStorage.setItem("lng", language);
		props.i18n.changeLanguage(language);

		window.fbq('trackCustom',  'HealthScore_Pageloaded');

	}, []);

	return (
		<BrowserRouter basename="/lp/health-score">
			<Switch>
				<Route path="/" exact component={HomePage1} />
                <Route path="/homepage1" exact component={HomePage1} />
				<Route path="/homepage2" exact component={HomePage2} />
				<Route path="/homepage3" exact component={HomePage3} />
				<Route path="/questions" exact component={Questions} />
				<Route path="/Instruction" exact component={InstructionPage} />
				<Route path="/Details" exact component={DetailsInputPage} />
				<Route path="/Results" exact component={ResultPage} />
			</Switch>
		</BrowserRouter>
	);
}

export default withTranslation("translations")(App);
