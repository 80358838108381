import React, { Component } from "react";

import QuestionCard from "./QuestionCard";

import { question4, question4Hindi } from "../../utils";
import SliderCard from "./SliderCard";
import "./questions.css";
import { Link, withRouter } from "react-router-dom";
import zapierService from "../../service/zapier";
import healthScoreFormula from "../ResultPage/healthScoreFormula";
import { useTranslation, Trans } from "react-i18next";

//change name of class component
class Question4 extends Component {
	constructor(props) {
		super(props);
		this.pageRef = React.createRef();
		this.seeResultsRef = React.createRef();

		this.state = {
			questions: question4,
			sliderValue: "0",
			secondSliderValue: "0",
			seeResults: true,
			name: props.name,
			seeNext: false,
		};
	}

	onSelect = (
		questionNumber,
		selectedAnswerIndex,
		selectedText,
		isPreviouslySelected,
		isMultipleAnswers,
		answerWeightage
	) => {
		//spreading the current state
		let dummyStateQuestions = [...this.state.questions];
		let totalQuestions = this.state.questions.length;

		//copying already/previous selected value of the questionNumber 1.1 or 1.2 ... for easy to read code, will be put back later.
		let alreadySelectedValue =
			dummyStateQuestions[questionNumber].selectedValue;

		//questionWeightage
		let alreadyQuestionScore =
			dummyStateQuestions[questionNumber].questionScore;

		// think for the case of multiple answers
		if (isMultipleAnswers) {
			//toggle for multiple clicks on an option
			dummyStateQuestions[questionNumber].options[
				selectedAnswerIndex
			].isSelected = isPreviouslySelected ? 0 : 1;

			alreadyQuestionScore += answerWeightage;

			//appending more  into the alreadySelectedValue
			alreadySelectedValue += `${selectedText} & `;
		} else {
			//toggle for multiple clicks on an option
			dummyStateQuestions[questionNumber].options[
				selectedAnswerIndex
			].isSelected = isPreviouslySelected ? 0 : 1;

			//finding the index of the previous selected option/text;

			let previousSelectedOption = dummyStateQuestions[
				questionNumber
			].options.findIndex((option) => option.text == alreadySelectedValue);

			//turning off the previous selected answer
			if (previousSelectedOption >= 0) {
				dummyStateQuestions[questionNumber].options[
					previousSelectedOption
				].isSelected = 0;
			}

			//only single selection of value
			alreadySelectedValue = selectedText;

			//question score
			alreadyQuestionScore = answerWeightage;
		}

		//think of an if condition here,else code will break

		if (dummyStateQuestions[questionNumber + 1]) {
			dummyStateQuestions[questionNumber + 1].toShow = true;
			setTimeout(() => {
				this.props && this.props.scrollFunc();
			}, 100);
		}
		

		//putting the selectedValue back to the original array;
		dummyStateQuestions[questionNumber].selectedValue = alreadySelectedValue;
		dummyStateQuestions[questionNumber].questionScore = alreadyQuestionScore;

		this.setState({ question: { ...dummyStateQuestions } }, () => {
			console.log(this.state.questions, "selectedQuestions");

			if (this.state.questions[totalQuestions - 1].selectedValue) {
				this.setState(
					{
						seeResults: false,
					},
					() => {
						this.seeResultsRef &&
							this.seeResultsRef.current &&
							this.seeResultsRef.current.scrollIntoView();
					}
				);
			}
		});
	};

	toggleSeeNext = () => {
		this.setState(
			{
				seeNext: true,
			},
			console.log(this.state.seeNext, "_)_)_)_)_")
		);
	};

	sliderChange = (sliderValue, question) => {
		if (question == 1){
			this.setState({
				sliderValue: sliderValue,
			});
			window.fbq('trackCustom',  'HealthScore_Q4_Willing_Own_Coaching_App');
		}
		else {
			this.setState(
				{
					secondSliderValue: sliderValue,
				},
				() => {
					this.seeResultsRef &&
						this.seeResultsRef.current &&
						this.seeResultsRef.current.scrollIntoView();
				}
			);
			window.fbq('trackCustom',  'HealthScore_Q4_Expected_Monthly_Income');
		}
	};

	setSeeResults = () => {
		this.setState(
			{
				seeResults: false,
			},
			() => {
				this.seeResultsRef &&
					this.seeResultsRef.current &&
					this.seeResultsRef.current.scrollIntoView();
			}
		);
	};

	handleSeeResults = () => {
		const { questions, sliderValue, secondSliderValue } = this.state;
		const { userDetails } = this.props;

		console.log("Make Payload", userDetails);
		const payloadQuestions = questions.map((question, index) => {
			return `Answer 4.${index + 2} -> ${question.selectedValue}`;
		});

		let totalScore = questions.reduce((acc, curr) => {
			return acc + curr.questionScore;
		}, 0);

		let sliderScore = 0;

		if (sliderValue > 50 && sliderValue <= 100) sliderScore = 5;

		let secondSliderScore = 0;

		if (secondSliderValue > 300000) secondSliderScore = 5;

		console.log(payloadQuestions, "payloadQuestions");
		const willingnessPercentage = `Answer 4.1 -> willingnessPercentage -> ${sliderValue}`;
		payloadQuestions.unshift(willingnessPercentage);

		localStorage.setItem("question4Score", secondSliderScore + sliderScore);

		let compiledValue = healthScoreFormula();
		const payloadFor4 = {
			// question4: payloadQuestions.join("\n  "),
			question4Score: `${sliderValue} --> score ${sliderScore} `,
			question4_2Score: `${secondSliderValue} --> score ${secondSliderScore} `,
			totalScore: compiledValue,
			...userDetails,
		};
		let lang = localStorage.getItem("lng");

		zapierService(payloadFor4)
			.then((res) => {
				console.log(res, "+++_++++++ 2222");
				//
				// this.props.navigation("next");
				this.props.history.push(`/results?lang=${lang}`, payloadFor4);
			})
			.catch((err) => {
				console.log(err);
			});
			window.fbq('trackCustom',  'HealthScore_See_Results');
	};

	render() {
		const { questions } = this.state;
		let lang = localStorage.getItem("lng");

		const languageHindi = lang == "hn" ? true : false;
		return (
			<div className="QuestionWrapper question4-container" ref={this.pageRef}>
				{/* <h1 style={{ color: "white" }}>QUESTION 2</h1>
				<h1 style={{ color: "white" }}>QUESTION 1</h1> */}

				<SliderCard
					sliderChange={this.sliderChange}
					sliderValue={this.state.sliderValue}
					toggleSeeNext={this.toggleSeeNext}
					question={1}
					languageHindi={languageHindi}
				/>

				{this.state.seeNext ? (
					<SliderCard
						sliderChange={this.sliderChange}
						sliderValue={this.state.secondSliderValue}
						toggleSeeNext={this.setSeeResults}
						question={2}
						languageHindi={languageHindi}
					/>
				) : (
					""
				)}

				{/* {this.state.seeNext ? (
					<>
						{questions.map((ques, index) => {
							return (
								<QuestionCard
									question={ques}
									key={index}
									source={index}
									onSelect={this.onSelect}
								/>
							);
						})}
					</>
				) : (
					""
				)} */}
				{!this.state.seeResults && (
					<button
						id="see-resuts-cta"
						disabled={this.state.seeResults}
						onClick={this.handleSeeResults}
						ref={this.seeResultsRef}
					>
						<Trans i18nKey="question4.results">See Results</Trans>
					</button>
				)}
			</div>
		);
	}
}

export default withRouter(Question4);
