import { React, useState } from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import "./modal.css";
import { Link, useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const CustomModal = (props) => {
	const { modalStatus } = props;

	const [open, setOpen] = useState(true);
	const history = useHistory();

	console.log(modalStatus, "+++++modalStatus");

	return (
		<div className="modal-container">
			<Modal
				closeIcon
				onClose={() => props.setmodalStatus("")}
				open={modalStatus}
			>
				{modalStatus == "instruction-modal" ? (
					<div style={{ padding: "16px" }}>
						<h4 className="modal-header">
							<Trans i18nKey="leaveModal.heading">
								Don’t want to take this assessment?
							</Trans>{" "}
						</h4>
						<p id="modal-content">
							<Trans i18nKey="leaveModal.content">
								It takes less than 2 min usually if you don’t overthink while
								you answer. On completing this assessment you get to know a lot
								about your coaching’s personality.
							</Trans>{" "}
						</p>
						<div className="modal-bottom">
							<button
								className="modal-btn1"
								onClick={() => {
									props.setmodalStatus("");
								}}
							>
								<Trans i18nKey="leaveModal.agree">Take Assesment</Trans>{" "}
							</button>
							<p
								className="modal-btn2"
								onClick={() => {
									history.push("/");
								}}
							>
								<Trans i18nKey="leaveModal.disagree">Skip for now</Trans>{" "}
							</p>
						</div>
					</div>
				) : modalStatus == "question-modal" ? (
					<Modal.Content>
						<h4 className="modal-header">
							<Trans i18nKey="leaveModal2.heading">
								Don’t want to take this assessment?
							</Trans>{" "}
						</h4>
						<p id="modal-content">
							<Trans i18nKey="leaveModal2.content">
								You are just a couple of minutes away from knowing the result.
								Don’t miss out!
							</Trans>{" "}
						</p>
						<div className="modal-bottom">
							<p
								className="modal-btn2"
								onClick={() => {
									history.push("/");
								}}
							>
								<Trans i18nKey="leaveModal2.disagree">No, Cancel!</Trans>{" "}
							</p>
							<button
								className="modal-btn1"
								onClick={() => {
									props.setmodalStatus("");
								}}
							>
								<Trans i18nKey="leaveModal2.agree">Continue</Trans>{" "}
							</button>
						</div>
					</Modal.Content>
				) : (
					""
				)}
			</Modal>
		</div>
	);
};

export default CustomModal;

{
}
