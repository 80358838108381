import { React, useState } from "react";
import "./instructionsPage.css";
import backBtn from "../../resources/backBtn.png";
import { Link, useHistory } from "react-router-dom";
import Modal from "../Modal";

import ModalImage from "../../resources/ModalImage.svg";

const InstructionPage = () => {
	const history = useHistory();

	const [modalStatus, setmodalStatus] = useState();
	return (
		<div className="Instruction-container">
			<img
				src={backBtn}
				id="instruction-back-btn"
				onClick={() => setmodalStatus("instruction-modal")}
			/>
			<h3 className="Instruction-container-header">
				Your Coaching’s
				<br /> Health Check-up
				<img src={ModalImage} alt="Image" />
			</h3>

			<div className="details-card">
				<h3 id="details-card-heading">Why you should take this assessment?</h3>
				<ul>
					<li>
						This assessment is based on scienifically validated models of
						behavourial education. Knowing it will help you better understand
						where your coaching stands in today’s educators community.
					</li>

					<li>
						So, learn about what you do, why you do, and once you understand
						your coaching’s personality you can use this information to become a
						more prolfiic educator that your students need and increase your
						chance of success!
					</li>
				</ul>
				<div className="read-instructions">
					* Please read the questions carefullly and try not to over think the
					answers.
				</div>
				<div className="instruction-container-bottom">
					<div id="instruction-container-bottom-text">
						* It will take less than 2 minutes to complete this assessment
					</div>
					<Link to="/details">
						<button id="takeAssessmentCta">Take Assessment</button>
					</Link>
				</div>
			</div>

			{modalStatus ? (
				<Modal modalStatus={modalStatus} setmodalStatus={setmodalStatus} />
			) : (
				""
			)}
		</div>
	);
};

export default InstructionPage;
