const zapierService = (payload) => {
	// https://hooks.zapier.com/hooks/catch/4460305/b30yex1/
	return new Promise((resolve, reject) => {
		fetch("https://hooks.zapier.com/hooks/catch/4460305/bu3bxyh/", {
			method: "POST",
			headers: {
				// "Content-Type": 'application/json',
			},
			body: JSON.stringify(payload),
		})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				resolve(data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export default zapierService;
