import React from "react";
import "./questionCard.css";
import { useTranslation, Trans } from "react-i18next";

const QuestionCard = ({ question, source, onSelect }) => {
	return question.toShow ? (
		<div className="card-container">
			{source !== 0 && <div className="WhiteLine" />}
			<div className="question">{question.question}</div>
			{question && question.isMultiple && (
				<div className="MultpleNote">
					<Trans i18nKey="questioncard.multiple">
						* You may choose multiple here
					</Trans>
				</div>
			)}
			<div
				className="answer-btn-group"
				//change the style and classname
				style={{
					display: question && question.display ? question.display : "flex",
					gridTemplateColumns: "1fr 1fr",
					columnGap: "12px",
				}}
			>
				{question &&
					question.options &&
					question.options.map((option, index) => {
						return (
							//make this button a seperate component
							<button
								key={index}
								className={
									option.isSelected ? "selected-option" : "unselected-option"
								}
								onClick={() => {
									onSelect(
										source,
										index,
										option.text,
										option.isSelected,
										question.isMultiple,
										option.answerWeightage
									);
								}}
							>
								{option.text}
							</button>
						);
					})}
			</div>
		</div>
	) : (
		""
	);
};

export default QuestionCard;
